<template>
    <input
        :id="id || name"
        ref="checkbox"
        v-model="proxyChecked"
        v-tippy="{ content: disabled && disabledReason ? disabledReason : '', trigger: 'mouseenter' }"
        :class="cn(
            'size-4 rounded shadow-sm border-gray-300',
            'focus:ring-0 focus:ring-offset-0 focus-visible:ring-2 focus-visible:ring-offset-2',
            !disabled && color === 'blue' && 'text-blue-500 focus-visible:ring-blue-500',
            !disabled && color === 'tenant' && 'text-tenant-500 focus-visible:ring-tenant-500',
            !disabled && color === 'black' && 'text-gray-950 focus-visible:ring-gray-950',
            !disabled && error && 'border-red-500 text-red-500 focus-visible:ring-red-500',
            disabled && 'cursor-not-allowed bg-gray-100 text-gray-400',
            $attrs.class ?? '',
        )"
        :name="name"
        :value="value"
        type="checkbox"
        @click="onClick"
    >
</template>

<!-- Inline MDN -->
<!-- name attribute is the check group/category name. -->
<!-- value attribute is the check option name. -->
<!-- id attribute is usually the same as value and there to make Label's for work -->

<script lang="ts" setup>
    import { ref, computed, watch } from 'vue';
    import { directive as vTippy } from 'vue-tippy';
    import cn from '@aspect/shared/utils/cn.ts';

    const props = withDefaults(defineProps<{
        id?: string;
        name: string;
        checked?: boolean | boolean[];
        indeterminate?: boolean;
        value?: boolean|null;
        error?: string;
        disabled?: boolean;
        disabledReason?: string;
        readonly?: boolean;
        color?: 'blue' | 'tenant' | 'black';
    }>(), {
        id: '',
        checked: false,
        indeterminate: false,
        disabled: false,
        value: null,
        readonly: false,
        color: 'blue',
    });

    const emit = defineEmits(['update:checked']);
    const checkbox = ref<HTMLInputElement>();

    const proxyChecked = computed({
        get() {
            return props.checked;
        },

        set(val) {
            if (props.disabled || props.readonly) {
                return;
            }

            emit('update:checked', val);
        },
    });

    watch(() => props.indeterminate, (value) => {
        if (checkbox.value) {
            checkbox.value.indeterminate = value;
        }
    });

    function onClick(event: MouseEvent) {
        if (props.disabled || props.readonly) {
            event.preventDefault();
        }
    }
</script>
